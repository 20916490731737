<template>
    <v-container fluid>

        <!-- Section Path File/Modul -->
        <v-breadcrumbs 
            :items="pathModul"
            divider="-"
            normal
            class="pathModul"
        ></v-breadcrumbs>

        <!-- Section Filtering Input/Select/Button -->
       <v-container fluid :class="$vuetify.breakpoint.lgAndUp? 'px-6 mt-2' : 'px-1'">

            <v-row align="center">

                <!-- Column Select Station -->
                <v-col
                class="col-input-filter"
                >
                    <v-select
                    v-on:change="selectValueStation"
                    :items="itemStation"
                    label="Station"
                    prepend-inner-icon="mdi-store-marker"
                    solo
                    dense
                    hide-details
                    ></v-select>
                </v-col>

                <!-- Column Select Year -->
                <!-- <v-col
                class="col-input-filter col-dateYear"
                >
                    <v-icon>
                      mdi-calendar
                    </v-icon>
                    <v-select
                    v-on:change="selectValueDate"
                    :items="dropdownDate"
                    label="Date"
                    class="input-date-year"
                    >
                    </v-select>
                </v-col> -->

                <!-- Column Select Month -->
                <v-col
                class="col-input-filter"
                >
                    <v-menu
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    v-model="modalCalendar"
                    >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                      v-model="dateFormat"
                      label="Date of Calibration"
                      prepend-inner-icon="mdi-calendar-today"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      solo
                      dense
                      hide-details
                      ></v-text-field>
                    </template>
                    <v-date-picker
                    v-on:change="selectDate"
                    v-model="dateFormat"
                    @input="modalCalendar = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col
                cols="12"
                sm="6"
                md="4"
                >
                    <v-file-input
                    label="File input"
                    show-size
                    truncate-length="15"
                    id="file"
                    ref="file"
                    v-on:change="handleFileUpload( $event )"
                    solo
                    dense
                    hide-details
                    ></v-file-input>
                </v-col>

                <!-- Column Generate Button -->
                <v-col
                cols="12"
                sm="6"
                md="4"
                class="col-btn-generateReport"
                >
                    <v-btn
                    class="ma-2 white--text btn-upload button"
                    solo
                    dense
                    normal
                    :disabled="file == null || valStation == null"
                    v-on:click="submitFile()"
                    >
                      SUBMIT
                    </v-btn>

                    <v-btn
                    class="ma-2 white--text btn-upload button"
                    solo
                    dense
                    normal
                    @click="BackToCalibrationRecord"
                    >
                      BACK
                    </v-btn>
                </v-col>

            </v-row>


        <!-- Section Result Generated Report (TABLE) -->
        <v-card class="pa-4" style="max-height: calc(100vh - 230px);overflow-y:auto;">
            <!-- Mini Title (Table Title) -->
            <v-subheader
            class="subTitle fontSubTitle-blue"
            >
              Calibration Record For {{stationID}} ({{dateSelected}})
            </v-subheader>

            <!-- Table -->
                <v-data-table
                :headers="thead_calibration_upload"
                :items="tbody_calibration_upload"
                :header-props="{ sortIcon: null }"
                class="elevation-1 tableCalibrationUpload headerDtSarawak"
                :items-per-page="5"
                no-select-on-click
                >

                    <template v-slot:[`item.datetimeUpload`]="{ item }">
                        <!-- {{ item.datetime_a }} -->
                        <span v-html="item.datetimeUpload"></span>
                    </template>
                    <template v-slot:[`item.fileDoc`]="{ item }">
                        <v-icon
                        color="#02b2c3"
                        v-html="item.fileDoc"
                        @click="openFileDoc(item)"
                        >
                        
                        </v-icon>
                    </template>
                    <template v-slot:[`item.delDetails`]="{ item }">
                        <v-icon
                        color="red"
                        v-html="item.delDetails"
                        @click="deleteItem(item)"
                        >
                        
                        </v-icon>
                    </template>

                </v-data-table>


                <template>
                    <div class="text-center" style="margin: 30px 0px;">
                        <v-progress-circular
                        id="loadingUpload"
                        :rotate="360"
                        :size="100"
                        :width="15"
                        :value="value"
                        color="rgb(1 157 191)"
                        >
                        {{ value }}
                        </v-progress-circular>
                    </div>
                </template>


                <!-- Popups/Dialogs Section - Success Upload -->
                <div class="text-center">
                    <v-dialog
                    v-model="dialog"
                    width="500"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                        style="display:none;"
                        id="showSuccessUpload"
                        color="green lighten-1"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        small
                        >
                            <v-icon small>mdi-note-check-outline</v-icon>
                        </v-btn>
                    </template>

                    <v-card>
                        <v-card-title class="text-h5" style="background:#106f79;color:white;">
                        Upload Complete <v-icon style="color:white;margin-left:15px;">mdi-note-check-outline</v-icon>
                        </v-card-title>

                        <v-card-text style="padding:20px 24px;font-weight:bold;">
                        Your file were uploaded successfully
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="#106f79"
                            text
                            @click="dialog = false"
                        >
                            OK
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                    </v-dialog>

                    <v-dialog v-model="dialogConfirmDelete" width="500">
                        <v-card>
                          <v-card-title class="text-h5" style="background:#106f79;color:white;">
                            Delete Calibration Record
                            <v-icon style="color:white;margin-left:15px;"
                              >mdi-text-box-check-outline</v-icon
                            >
                          </v-card-title>
                
                          <v-card-text style="padding:20px 24px;font-weight:bold;">
                            Confirm to delete calibration record? 
                          </v-card-text>
                
                          <v-divider></v-divider>
                
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="confirmDelete()">
                              YES
                            </v-btn>
                            <v-btn color="secondary" text @click="dialogConfirmDelete = false">
                              NO
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                
                      <v-dialog v-model="dialogSucceedDelete" width="500">
                        <v-card>
                          <v-card-title class="text-h5" style="background:#106f79;color:white;">
                            Delete Calibration Record
                            <v-icon style="color:white;margin-left:15px;"
                              >mdi-delete</v-icon
                            >
                          </v-card-title>
                
                          <v-card-text style="padding:20px 24px;font-weight:bold;">
                            Calibration record deleted successfully.
                          </v-card-text>
                
                          <v-divider></v-divider>
                
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="dialogSucceedDelete = false">
                              OK
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                
                      <v-dialog v-model="dialogErrorPending" width="500">
                        <v-card>
                          <v-card-title class="text-h5 red lighten-2" style="color:white;">
                            Warning!
                            <v-icon style="color:white;margin-left:15px;">mdi-alert</v-icon>
                          </v-card-title>
                
                          <v-card-text style="padding:20px 24px;font-weight:bold;">
                            Something went wrong. Please try again.
                          </v-card-text>
                
                          <v-divider></v-divider>
                
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              style="color:red !important;"
                              color="primary"
                              text
                              @click="dialogErrorPending = false"
                            >
                              OK
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>

                </div>

        </v-card>


        <!-- Section Upload File (TABLE) -->
        <v-container class="container-upload-file form-uploadFile">
        </v-container>

        </v-container>
    </v-container>
</template>

<script>
import axios from 'axios';
// import Papa from "papaparse";

export default {
    data: () => ({

        pathModul: [
            {
                text: 'Home',
                disabled: false,
                // href: '/',
                href: '/Mapviewer',
            },
            {
                text: 'Report - Calibration Record',
                disabled: false,
                href: '/Operational/Calibration',
            },
            {
                text: 'Upload File',
                disabled: true,
                href: '/Operational/CalibrationUpload',
            },
        ],
        itemStation: [],
        dateFormat: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu: false,
        modalCalendar: false,
        menu2: false,

        // Variable/Object for value filtering
        valStation : null,
        valDate : null,
        valYear : null,
        valMonth : null,

        // Title - Subtitle After Generate Data
        stationID: null,
        dateSelected: null,
        monthText: [
            "Null",
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ],

        file: null,
        interval: {},
        value: 0,
        dialog: false,
        dialogConfirmDelete: false,
        dialogSelect: false,
        dialogSucceedDelete: false,
        dialogErrorPending: false,
        delId: '',

        // DataTable
        // thead_GetReady: [
        //     {text: "Station ID",value: "stationId", divider: true, align: "center"},
        //     {text: "Date of Maintenance",value: "datetime", divider: true, align: "center"},
        //     {text: "Uploader",value: "uploader", divider: true, align: "center"},
        //     {text: "Document",value: "fileDoc", divider: true, align: "center"},
        //     {text: "Delete",value: "delDetails", divider: true, align: "center"},
        // ],
        thead_calibration_upload: [
            {text: "STATION ID",value: "stationid", divider: true, align: "center",sortable: false},
            {text: "DATE OF CALIBRATION",value: "datetimeUpload", divider: true, align: "center",sortable: false},
            {text: "UPLOADER",value: "uploader", divider: true, align: "center",sortable: false},
            {text: "DOCUMENT",value: "fileDoc", divider: true, align: "center",sortable: false},
            {text: "DELETE",value: "delDetails", divider: true, align: "center",sortable: false},
        ],
        tbody_calibration_upload: [],

    }),

    methods: {

        convert12Hours(time) {
          var H = +time.substr(0, 2);
          var h = H % 12 || 12;
          var ampm = (H < 12 || H === 24) ? "AM" : "PM";
          time = h + time.substr(2, 3) + time.substr(5, 3) + ' ' + ampm;
          return time;
      },

      convertDateFormat(date){
          var dd = date.split("-")[2];
          var mm = date.split("-")[1];
          var yyyy = date.split("-")[0];
          date = dd + "/" + mm + "/" + yyyy;
          return date;
      },

        load(){
            this.getStation();

            this.valYear = this.dateFormat.split('-')[0];
            this.valMonth = this.dateFormat.split('-')[1];
        },

        getStation(){
            axios.get(this.globalUrl+'mqm2/miqims/stations', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                this.itemStation = [];

                for (let i = 0; i < response.data.length; i++) {
                    this.itemStation.push(response.data[i].STATION_ID + " - " + response.data[i].LOCATION);  
                }
                
            })
            .catch(error => {
                console.log(error);
            })
        },

        // Get value from Selected Station
        selectValueStation: function(e) {
            // console.log(e);
            this.valStation = e;
        },

        // Get value from Selected Date
        selectDate: function(e) {
            this.valDate = e;
            this.valYear = e.split('-')[0];
            this.valMonth = e.split('-')[1];
        },


        BackToCalibrationRecord: function() {
            window.location.href = "/Operational/Calibration";
        },

        handleFileUpload(e){
            // console.log(this.$refs);
            // this.file = this.$refs.file.files[0];

            if(e == undefined){
                this.file = null;
            }
            else{
                this.file = e;
            }

            console.log(this.file);
        },

        submitFile(){

            document.getElementById('loadingUpload').style.display = "inline-flex";

            // Keluarkan Details SubText
            this.stationID = this.valStation.split(' - ')[0];
            for (let i = 0; i < this.monthText.length; i++) {
                if (i == this.dateFormat.split('-')[1]) {
                    this.dateSelected = this.monthText[i] + ' ' + this.dateFormat.split('-')[0];
                }
            }

            let formData = new FormData();

            formData.append('files', document.getElementById('file').files[0]);

            if (document.getElementById('file').files[0] != null) {
                axios.post(this.globalUrl+'mqm2/calib/upload?stationid='+this.stationID+'&dt='+this.dateFormat,
                    formData,
                    {
                        headers: {
                            Authorization: 'Bearer ' + this.$store.getters.user.token,
                        }
                    }
                ).then(function(response){
                    console.log('SUCCESS!!');
                    this.interval = {};
                },
                // setTimeout(function () {  }.bind(this), 5000),
                this.interval = setInterval(() => {
                    
                    if (this.value === 100) {
                        this.GenerateTable(this.stationID,this.valYear,this.valMonth);
                        document.getElementById('loadingUpload').style.display = "none";
                        return (this.value = 0)
                    }
                    this.value += 20
                }, 1000)
                )
                .catch(function(){
                    // console.log('FAILURE!!');
                });
            }
            else{
                console.log("File Null");
            }

            
        },


        GenerateTable(station,year,month){

            this.beforeDestroy();

            if (station === null) {
                console.log("error");
                this.tbody_calibration_upload = [];
            }
            else{
                this.tbody_calibration_upload = [];

                axios.get(this.globalUrl+'mqm2/calib/data?stationid='+station+'&year='+year+'&month='+month, {
                    headers: {
                        'Authorization': 'Bearer ' + this.$store.getters.user.token,
                    }
                })
                .then((response) => {

                    for (let i = 0; i < response.data.length; i++) {
                        response.data[i]["datetimeUpload"] = this.convertDateFormat(response.data[i]["datetimeUpload"]);
                    }

                    response.data.forEach(function(res){
                        res["fileDoc"] = 'mdi-file-document';
                        res["delDetails"] = 'mdi-delete';
                        // if (res["datetimeUpload"] != null) {
                        //     res["datetimeUpload"] = res["datetimeUpload"].replaceAll('T', '<br>');
                        // }
                        
                    });

                    console.log(response.data);
                    document.getElementById('showSuccessUpload').click();
                    this.tbody_calibration_upload = response.data.slice(0, 1);

                    document.querySelector(".subTitle").style.display = "block";
                    
                })
                .catch(error => {
                    console.log(error);
                })
            }


        },

        beforeDestroy () {
            clearInterval(this.interval)
        },


        openFileDoc: function(e) {
            console.log(e);
            // window.location.href = e.filepath;
            window.open(e.filepath, '_blank');
        },

        deleteItem: function(e) {

            this.dialogConfirmDelete = true;

            this.delId = e.id

            // console.log(e);
            // axios.get(this.globalUrl+'mqm2/calib/del?id='+e.id, {
            //     headers: {
            //         'Authorization': 'Bearer ' + this.$store.getters.user.token,
            //     }
            // })
            // .then((response) => {
            //     console.log("Success Deleted");
            //     this.tbody_calibration_upload = [];
            //     document.querySelector(".subTitle").style.display = "none";
            // })
            // .catch(error => {
            //     console.log(error);
            // })
        },

        confirmDelete(){

            this.dialogConfirmDelete = false;

            axios.get(this.globalUrl+'mqm2/calib/del?id='+this.delId, {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                console.log("Success Deleted");
                this.tbody_calibration_upload = [];
                this.dialogSucceedDelete = true;
                document.querySelector(".subTitle").style.display = "none";
            })
            .catch(error => {
                this.dialogErrorPending = true;
                console.log(error);
            })
        },

        
    },

    mounted(){
        this.load();
    }

}
</script>

<style lang="scss">

    @import '~scss/main';

    /* Section Path File/Modul */
    .pathModul{
        padding-bottom: 0px;
    }

    .pathModul > li:nth-child(5) > a,
    .pathModul > li:nth-child(5) > div{
        color: black !important;
    }

    /* Section Filtering Input/Select/Button */
    .container-filtering{
        padding: 0px;
    }

    .col-dateYear{
        display: flex !important;
    }

    .col-dateYear > i{
        margin-right: 9px !important;
    }

    .btn-download-table{
        margin-right: 8px;
    }

    .column-upload-button{
        padding: 0px 0px 10px 0px !important;
    }

    /* Section Generate Result Datatable Vue */
    #loadingUpload{
        display: none;
    }

    .subTitle{
        text-align: center !important;
        background: white;
        // color: #1976D2 !important;
        display: none;
    }

    // .tableCalibrationUpload > div:nth-child(1) > table > thead > tr > th{
    //     background: #4495D1;
    //     color: white !important;
    //     font-weight: bold;
    // }

    .form-datatable{
        padding: 10px 0px 0px 0px;
    }

    .tableCalibrationUpload > .v-data-table__wrapper {
        overflow-x: auto;
        overflow-y: auto;
        max-height: 670px;
    }

    /* Section Upload File */
    .form-uploadFile{
        display: none;
    }

</style>
